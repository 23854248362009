import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { InputesService } from 'src/app/services/inputes.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-send-mail-password',
  templateUrl: './send-mail-password.component.html',
  styleUrls: ['./send-mail-password.component.scss'],
})
export class SendMailPasswordComponent {
  constructor(
    private auth: AuthService,
    private is: InputesService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private us: UserService,
  ) {}

  password: string = '';
  email: string = '';
  password2: string = '';
  passwordVisible = false;
  passwordVisible2 = false;

  errors: any = [];

  updatePassword() {
    this.email = this.email.toLocaleLowerCase();
    const re = /\S+@\S+\.\S+/;
    if (!re.test(this.email)) {
      this.errors.push('eMail non valide');
    } else {
      this.us.forget({ email: this.email, type: "user" }).subscribe((res) => {
        window.location.href = 'https://romain.care';
      });
    }
  }
  login() {
    this.router.navigateByUrl('/login');
  }
  landing() {
    this.router.navigateByUrl('/');
  }
  validatePassword(password: any) {
    this.errors = [];
    const minLength = 8;
    const maxLength = 20;
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /[0-9]/;
    const regexSpecial = /[!@#$%^&*()_+-={};':"|,.<>£€¨°§?]/;
    if (password.length < minLength || password.length > maxLength) {
      this.errors.push("Mot de passe doit être d'au moins 8 caractères");
    }
    if (!regexUpperCase.test(password)) {
      this.errors.push('Le mot de passe doit contenir une lettre majuscule');
    }
    if (!regexLowerCase.test(password)) {
      this.errors.push('le mot de passe doit contenir une lettre minuscule');
    }
    if (!regexNumber.test(password)) {
      this.errors.push('le mot de passe doit contenir un nombre');
    }
    if (!regexSpecial.test(password)) {
      this.errors.push('Le mot de passe doit contenir un caractère spécial');
    }
    if (password !== this.password2) {
      this.errors.push('Les deux mots de passe ne correspondent pas');
    }

    if (this.errors.length) {
      return false;
    } else {
      return true;
    }
  }
}
