// export const environment = {
//     production: false,
//     apiURL: 'http://127.0.0.1:4000',
//     userURL: 'http://127.0.0.1:4000'
// };

export const environment = {
    production: false,
    apiURL: 'https://admin.romain.care',
    userURL: 'https://romain.care'
};